@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@609&family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Russo+One&display=swap');

.title {
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    @apply text-[24px];
}

.description {
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    @apply text-[17px] leading-[26px] text-[#7D7987] mt-2 line-clamp-[7];
}
.read_more {
    font-family: "Mulish", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    @apply text-[17px] leading-[26px] text-[#4089ED] mt-2 flex items-center ;
}